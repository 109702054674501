import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Table from "../../components/Table/Table"

import {
  Delimiter,
  MainTitle,
  SubTitle,
  Text,
} from "../Catalog/PageCatalog.styled"
import Product from "../../components/Product/Product"
import Feedback from "../../components/Feedback/Feedback"
import Layout from "../../layouts/MainLayout/Layout"
import Header from "../../components/Header/Header"
import Wrapper from "../../components/Wrapper/Wrapper"
import Seo from "../../config-components/seo"
import Footer from "../../components/Footer/Footer"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import productsData from "../../data/products";

const MarinaPlastic = ({ location, pageContext }) => {
  const query = useStaticQuery(graphql`
    query PvhLodkaQuery {
      photos: allFile(
        filter: { relativeDirectory: { regex: "/images/catalog/marina-plastic/" } }
      ) {
        edges {
          node {
            publicURL
            relativeDirectory
          }
        }
      }
    }
  `)

  const images = query.photos.edges.map((el) => ({
    url: el.node.publicURL,
    alt: el.node.relativeDirectory.split("/").slice(-1)[0],
  }))
  images.sort((a, b) => (a.alt > b.alt ? 1 : -1))

  const {
    breadcrumb: { crumbs },
  } = pageContext

  const {marinaPlastic} = productsData

  return (
    <Layout>
      <Header page={location.pathname} />
      <Wrapper width={754}>
        <div style={{ width: "100%" }}>
          <Breadcrumb
            crumbs={crumbs}
            crumbLabel="Marina-plastic"
            crumbSeparator=" > "
          />
        </div>
        <Seo
          title={"Купить катамаран на пластиковых баллонах"}
          description={"Катамаран из пластика"}
          keywords={"Купить пластиковый катамаран, водный аттракцион"}
        />
        <MainTitle
          style={{
            paddingBottom: 0,
            marginBottom: 7,
            fontWeight: "bold",
            paddingTop: 50,
          }}
        >
          <h1>Катамараны на пластиковых баллонах "MARINA PLASTIC"</h1>
        </MainTitle>
        <Product
          images={images}
          info={marinaPlastic.mainInfo}
          price={marinaPlastic.price}
          priceStyle={{ color: "red" }}
        />

        <Delimiter />
        <Text style={{ borderBottom: "1px solid #e1e3e6", paddingBottom: 40 }}>
          <p>
            Катамаран «MARINA PLASTIC» построен на поплавках, изготовленных методом ротационного
            формования специальных марок полиэтилена (LDPE). Данный метод позволяет получить
            герметичное изделие без сварных швов, устойчивое к внешним воздействиям, в том числе и
            ультрафиолету. Срок службы такого изделия может достигать 50 лет.
          </p>
          <p>
            Модульная конструкция поплавков позволяет строить плавсредства требуемых под ваши задачи
            размеров по длине и ширине, а также и использовать различные компоновки (катамаран,
            тримаран и т.д.)
          </p>
          <p>
            Рама катамарана сварная, из алюминиевого бокса или нержавеющего профиля.
          </p>
          <p>
            Палуба-морская (бакелизированная) фанера, толщиной 9-12мм.
          </p>
          <p>
            Возможна комплектация катамарана различным оборудованием: сидения, маркизы и т.д.
          </p>
        </Text>
        <SubTitle>Комплектация</SubTitle>
        <Table values={marinaPlastic.specification} />

        <Delimiter />

        <div id={`feedback`}>
          <Feedback styles={{ marginBottom: "2rem", marginTop: "2rem" }} />
        </div>
      </Wrapper>
      <Footer />
    </Layout>
  )
}

export default MarinaPlastic
